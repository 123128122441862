html,
body {
  height: 100%;
  padding: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f8fcff;
}
